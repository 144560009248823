import React from 'react'

import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'

interface CrosslinksButtonProps {
  state: boolean
  setState: any
}

const CrosslinksButton: React.FC<CrosslinksButtonProps> = ({
  state,
  setState,
}) => {
  const handleClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setState(!state)
  }

  const { t } = useTranslation('translation')

  return (
    <Button
      className="cta_pes_button"
      variant="outlined"
      color="primary"
      onClick={handleClick}
    >
      <AutorenewIcon /> {t('Material aendern')}
    </Button>
  )
}

export default CrosslinksButton
