import React from 'react'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import ImageIcon from '@mui/icons-material/Image'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'

interface Preview3dButtonProps {
  preview3d: boolean
  setPreview3d: any
}

const Preview3dButton: React.FC<Preview3dButtonProps> = ({
  preview3d,
  setPreview3d,
}) => {
  const handleFullscreenClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setPreview3d(!preview3d)
  }

  const { t } = useTranslation('translation')

  let icon = (
    <ViewInArIcon className="iconButton" onClick={handleFullscreenClick} />
  )

  let title = t('3D-Ansicht aktivieren')
  if (preview3d) {
    icon = <ImageIcon className="iconButton" onClick={handleFullscreenClick} />

    title = t('3D-Ansicht deaktivieren')
  }

  return (
    <div className="d-block">
      <Tooltip title={title}>{icon}</Tooltip>
    </div>
  )
}

export default Preview3dButton
