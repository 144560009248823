import React from 'react'
import { Link } from 'gatsby'
import { Artist } from '../../types'
import { getProductsTemplateUrl } from '../../utils/utils'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { useTranslation } from 'react-i18next'

interface ArtistLinksProps {
  artists: Artist[]
  materialName: string
}

const ArtistLinks: React.FC<ArtistLinksProps> = ({ artists, materialName }) => {
  let children: any = []
  const { t } = useTranslation('translation')

  artists.map((artist: Artist) => {
    let link = getProductsTemplateUrl(
      materialName,
      [artist.slug],
      'besteller',
      '',
    )

    children.push(
      <Link key={'artistlink_' + artist.slug} to={link}>
        {t('von')} {artist.name}
      </Link>,
    )
  })

  if (artists.length == 0) {
    return <></>
  } else if (artists.length == 1) {
    return <div className="d-flex justify-content-center">{children}</div>
  } else {
    // TODO
    return <></>
  }
}

export default ArtistLinks
