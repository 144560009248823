let isWebGLContextAvailableCached: boolean | null = null

const detectWebGLContext = () => {
  if (isWebGLContextAvailableCached !== null)
    return isWebGLContextAvailableCached

  try {
    const canvas = document.createElement('canvas')
    const gl =
      canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    const isWebGLContextAvailable = gl && gl instanceof WebGLRenderingContext

    isWebGLContextAvailableCached = isWebGLContextAvailable

    return isWebGLContextAvailable
  } catch (error) {}

  return false
}

export { detectWebGLContext }
