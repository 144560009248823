import React from 'react'
import { ProductMaterial, ProductData } from '../../../types'
import SelectButton from '../../generic/SelectButton'
import { SelectButtonOption, ParsedSku } from '../../../types'
import { buildPesUrl } from '../../../utils/UrlUtils'
import { getMaterialGroups } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'

interface MaterialGroupSelectProps {
  materials: ProductMaterial[]
  productInformation: ParsedSku
  material: string
  currentMaterial?: ProductMaterial
  productData: ProductData
}

const MaterialGroupSelect: React.FC<MaterialGroupSelectProps> = ({
  materials,
  productInformation,
  material,
  currentMaterial,
  productData,
}) => {
  const { t } = useTranslation('translation')

  let display = false

  let templateUrl = buildPesUrl(
    material,
    '%s',
    productInformation.motiveId,
    productInformation.slug,
    productData.ratio,
  )

  let options: SelectButtonOption[] = []

  let foundMaterialGroups: string[] = []
  let filteredMaterials: any = []

  materials.map((material: ProductMaterial) => {
    if (getMaterialGroups().indexOf(material.materialgroup) !== -1) {
      if (foundMaterialGroups.indexOf(material.material) === -1) {
        if (
          typeof currentMaterial !== 'undefined' &&
          currentMaterial.size_slug == material.size_slug
        ) {
          filteredMaterials.push({
            material: material.material,
            productId: material.iid,
            urlSlug: material.url_slug,
          })
          foundMaterialGroups.push(material.material)
        }
      }
    }
  })

  // console.log(filteredMaterials)
  // console.log(foundMaterialGroups)

  let selectedKey = ''
  filteredMaterials.map((material: any) => {
    let option = { key: material.productId, name: t(material.material) }

    if (
      typeof currentMaterial !== 'undefined' &&
      currentMaterial.material == material.material
    ) {
      selectedKey = material.productId
    }
    options.push(option)
  })

  if (options.length > 0 && typeof currentMaterial !== 'undefined') {
    display = true
  }

  const buttonName = 'MaterialGroupSelect'

  if (display) {
    return (
      <SelectButton
        options={options}
        name={buttonName}
        selectedKey={selectedKey}
        templateUrl={templateUrl}
        replace={true}
      />
    )
  } else {
    return <></>
  }
}

export default MaterialGroupSelect
