import React from 'react'

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

interface ArtworksInsideButtonProps {
  state: boolean
  setState: any
}

const ArtworksInsideButton: React.FC<ArtworksInsideButtonProps> = ({
  state,
  setState,
}) => {
  const handleClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setState(!state)
  }

  const { t } = useTranslation('translation')

  return (
    <Button
      className="cta_pes_button"
      variant="outlined"
      color="primary"
      onClick={handleClick}
    >
      <PhotoLibraryIcon /> {t('Enthaltene Motive')}
    </Button>
  )
}

export default ArtworksInsideButton
