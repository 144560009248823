import { createVariants } from './createVariant'

const createMaterial = (data: Record<string, any>, priceCountry: string) => {
  const definedMaterials = data.materialData || []

  const variants = createVariants(definedMaterials, priceCountry)

  return {
    name: data.material,
    productData: data.productData,
    signature: data.signature,
    variants,
  }
}

const filterMaterialsWithVariants = (materials: Record<string, any>[]) =>
  materials.filter(material => material.variants.getInStock().length > 0)

const createMaterialFactory = (
  data: Record<string, any>[],
  priceCountry: string,
) => {
  const materials = data.map(product => createMaterial(product, priceCountry))

  return {
    getMaterialsWithVariants: () => filterMaterialsWithVariants(materials),
  }
}

export default createMaterialFactory
