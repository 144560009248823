import React from 'react'

import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'

interface FullscreenProps {
  fullscreen: boolean
  setFullscreen: any
}

const Fullscreen: React.FC<FullscreenProps> = ({
  fullscreen,
  setFullscreen,
}) => {
  const handleFullscreenClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setFullscreen(!fullscreen)
  }

  const { t } = useTranslation('translation')

  let icon = (
    <FullscreenIcon className="iconButton" onClick={handleFullscreenClick} />
  )

  let title = t('Vorschau vergrößern')
  if (fullscreen) {
    icon = (
      <FullscreenExitIcon
        className="iconButton"
        onClick={handleFullscreenClick}
      />
    )

    title = t('Vorschau verkleinern')
  }

  return (
    <div className="d-none d-md-block">
      <Tooltip title={title}>{icon}</Tooltip>
    </div>
  )
}

export default Fullscreen
